import * as mutation from './authenticationMutationTypes'
import axios from '@/Services/axiosService'

const localStorageUserKey = process.env.VUE_APP_NAME + "_token";
const authenticationData = {
    namespaced: true,
    state: ()=>({ 
        currentUser:false,
        token:false
    }),
    mutations: {
        [mutation.SET_CURRENT_USER] (state,user){
            state.currentUser = user;
          },
          [mutation.SET_APP_USER_TOKEN](state,data){
            state.token = data;
            localStorage.setItem(localStorageUserKey, state.token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data;
          },
          [mutation.RESET_ALL_AUTH_DATA](state){
            state.currentUser = null,
            state.token = null
            localStorage.removeItem(localStorageUserKey);
          }
     },
    actions:{  
        checkForExistingUser({commit}){
            let localStorageUser = localStorage.getItem(localStorageUserKey);
            if(localStorageUser){
                commit(mutation.SET_APP_USER_TOKEN,localStorageUser);
                return true;
            }else{
                console.log("no local storage user found - need to login");
                return false;
            }
        },
        getCurrentUser({commit}){
            axios.get( '/user/get').then(resp =>{
                if(resp){ // TODO what do do here if no user is returned
                    if(resp.status == 200){
                        commit(mutation.SET_CURRENT_USER,resp.data);
                    }else{
                        commit(mutation.RESET_ALL_AUTH_DATA);
                    }   
                }
                return resp;
            }) .catch(() => {
                // handle error
                commit(mutation.RESET_ALL_AUTH_DATA);
              });
        },
        registerUser({commit},object){
            let data = object.data;

            axios.post('/user/make-account',data).then(resp =>{
                let theData = JSON.parse(JSON.stringify(resp.data))
                commit(mutation.SET_CURRENT_USER,theData.user);

                if(resp.data.token && resp.data.token != ""){
                    commit(mutation.SET_APP_USER_TOKEN,theData.token);
                }

                return resp;
            });
        },
        logoutUser({commit}){
            axios.get('/user/logout').then(()=> {
                commit(mutation.RESET_ALL_AUTH_DATA);
            });
        },
        loginUser({commit},object){
            let data = object.data;
            
            axios.post( '/user/login',data).then(resp =>{
                let theData = JSON.parse(JSON.stringify(resp.data))
                commit(mutation.SET_CURRENT_USER,theData.user);

                if(resp.data.token && resp.data.token != ""){
                    commit(mutation.SET_APP_USER_TOKEN,theData.token);
                }

                return resp;
            });
        },
     },
    getters:{  
        currentUser : state =>{
            return state.currentUser;
        },
        token : state =>{
            return state.token;
        }
    }
}

export {authenticationData};