<template>
   
        <v-navigation-drawer
          expand-on-hover
          rail
        >
          <v-list
            v-if="user"
          >
            <v-list-item
           
              prepend-avatar="https://randomuser.me/api/portraits/women/85.jpg"
              
              :subtitle="user.email"
            ></v-list-item>
            <v-list-item>
                <a @click="logout">Logout</a>
            </v-list-item>
          </v-list>
  
          <v-divider></v-divider>
  
          <v-list
            v-if="!user"
          >
            <v-list-item>
                <a @click="goToLogin">Login</a>
            </v-list-item>
            <v-list-item>
                <a @click="goToRegister">Register</a>
            </v-list-item>
          </v-list>
         
        </v-navigation-drawer>
  
      
  </template>
  <script>
    export default {
        methods:{
            logout(){
                this.$store.dispatch('authenticationData/logoutUser').then(() => {});
            },
            goToLogin(){
                this.$router.push('/login')
            },
            goToRegister(){
                this.$router.push('/register')
            }
        },
        computed:{
            user(){
                return this.$store.getters['authenticationData/currentUser'];
            },
            token(){
                return this.$store.getters['authenticationData/token'];
            }
        }
    }
  </script>