
<template>
    <v-app >
    <v-main>
      <v-container class="pa-0 ma-0" fluid>

        
        <v-row align="center" justify="center" class="p-0 m-0" >

          <v-col cols="12">
            <router-view/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>


export default {
  name: 'App',

  components: {
  },

  data: () => ({
  }),
  mounted(){
     this.initAuth();
    },
    methods:{
      initAuth(){
        this.$store.dispatch('authenticationData/checkForExistingUser')
          .then((resp) => {
            if(resp){
              if(!this.user || !this.token){
                this.$store.dispatch('authenticationData/getCurrentUser');
              }
            }
          }, error => {
              console.log(error)
          });
      }
    },
    computed:{
      user(){
          return this.$store.getters['authenticationData/currentUser'];
      },
      token(){
        return this.$store.getters['authenticationData/token'];
      }
    }
}
</script>
