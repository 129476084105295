<template>
    <v-row>
        <v-col cols="12">
            <v-text-field
            v-model="email"
            filled
            label="Your Email Address"
            clearable
            type="email"
          ></v-text-field>

        <v-text-field
            v-model="password"
            filled
            label="Password"
            clearable
            type='password'
          ></v-text-field>

          <v-text-field
            v-model="password_confirmation"
            filled
            label="Password"
            clearable
            type='password'
          ></v-text-field>


          <v-btn
            color="primary"
            elevation="2"
            raised
            @click='attemptRegistration()'
            >Register</v-btn>
        </v-col>
    </v-row>
</template>




<script>

export default {
    data(){
        return  {
            email:'',
            password:'',
            password_confirmation:''
        }
    },
    methods:{
        attemptRegistration(){
            this.$store.dispatch('authenticationData/registerUser',
                {axios:this.axios, data: {email:this.email, password:this.password,password_confirmation:this.password_confirmation}}
                
            )
            .then(() => {}, error => {
                console.log(error)
            });
        }
    }
}
</script>
Footer
