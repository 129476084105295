<template>
    <v-row>
        <v-col cols="12">
            <v-text-field
            v-model="email"
            filled
            label="Your Email Address"
            clearable
            type="email"
          ></v-text-field>

        <v-text-field
            v-model="password"
            filled
            label="Password"
            clearable
            type='password'
          ></v-text-field>



          <v-btn
            color="primary"
            elevation="2"
            raised
            @click='attemptLogin()'
            >Login</v-btn>
           
          

        </v-col>
    </v-row>
</template>




<script>



export default {
    data(){
        return  {
            email:'',
            password:'',
            password_confirmation:''
        }
    },
    methods:{
        attemptLogin(){
            this.$store.dispatch('authenticationData/loginUser',
                {axios:this.axios, data: {email:this.email, password:this.password}}
            )
            .then(() => {}, error => {
                this.$router.push({name:'Wizard'})
                console.log(error)
            });
        }
    }
}
</script>

