<template>
    <v-container>
      <login-form />
    </v-container>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>