<template>
  <v-container fluid class="mainConatiner">
    <v-row>
      <v-col cols="6" class="pa-0 ma-0">
        <v-text-field
        v-if="!userData"
          v-model="password"
          label="Password"
          type="password"
        >
        </v-text-field>
      </v-col>
      <v-col
      class="pa-0 ma-0"
      cols="6">
      <v-btn
      v-if="!userData"
      @click="getUserData"
      class="theme-btn continueButton"
    >
      Continue
    </v-btn>

      </v-col>
    </v-row>
   
    <v-row 
      v-if="!formComplete"
    >
    <v-expansion-panels variant="accordion"
    v-for="(user, i) in userData"
      :key="i"
      >
      <v-expansion-panel
        :title=user.name 
      >

      <v-expansion-panel-text>
        <v-row>
          <v-col
          class="pa-0 ma-0"
          >
            <v-checkbox :label="user.name + ' is attending'" 
            v-model="user.is_attending"
            @change="validateForm"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row v-if="user.menu_option != 'Kids' && user.is_attending">
          <v-col>
            <v-row>
              <v-col
              
              >
                <a
                v-if="user.hasKidsOption"
                  class="menuTypeLink"
                 @click="setToAdultMenu(user,false)">I prefer the kids menu</a>
              </v-col>
            </v-row>

            <v-row>
              <v-col
              
              >
                <v-select
                  :items="menuData.main.Starter"
                  label="Starter"
                  item-title="dish_name"
                  item-value="id"
                  @update:modelValue="userSelectionUpdated(user,'starter', $event)"
                ></v-select>

                <v-select
                  :items="menuData.main.Main"
                  label="Main"
                  item-title="dish_name"
                  item-value="id"
                  @update:modelValue="userSelectionUpdated(user,'main', $event)"
                ></v-select>

                <v-select
                  :items="menuData.main.Desert"
                  label="Desert"
                  item-title="dish_name"
                  item-value="id"
                  @update:modelValue="userSelectionUpdated(user,'desert', $event)"
                ></v-select>
                
                <v-text-field
                  label="Any dietary requirements?"
                  v-model="user.other_information"
                  @change="validateForm"
                ></v-text-field>

            </v-col>
            </v-row>
          </v-col>
        </v-row>


        <v-row v-if="user.menu_option == 'Kids' && user.is_attending">
          <v-col
          
          >
            <v-row>
              <v-col>
                 <a
                 class="menuTypeLink"
                 @click="setToAdultMenu(user,true)" >I prefer the adult menu</a>

              </v-col>
            </v-row>
            <v-row>
              <v-col
              
              >
                <v-select
                :items="menuData.kids.Starter"
                label="Starter"
                item-title="dish_name"
                item-value="id"
                @update:modelValue="userSelectionUpdated(user,'starter', $event)"
              ></v-select>

              <v-select
                :items="menuData.kids.Main"
                label="Main"
                item-title="dish_name"
                item-value="id"
                @update:modelValue="userSelectionUpdated(user,'main', $event)"
              ></v-select>

              <v-select
                :items="menuData.kids.Desert"
                label="Desert"
                item-title="dish_name"
                item-value="id"
                @update:modelValue="userSelectionUpdated(user,'desert', $event)"
              ></v-select>

              <v-text-field
                  label="Any dietary requirements?"
                  v-model="user.other_information"
                  @change="validateForm"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    
    </v-row>

    <v-row  class="directionsHolder" v-if="!allDataIsValid && userData && !formComplete">
      <v-col>
        <span>Enter all guest meal choices to continue</span>
      </v-col>
    </v-row>


    <v-row>
      <v-col>
        <v-btn
        v-if="allDataIsValid && !formComplete && !savingData"
          @click="saveChoices"
          class="theme-btn"
      >
        Save Choices
      </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="errorMessage">
      <v-col>
        <span class="errors">{{ errorMessage }}</span>
      </v-col>
    </v-row>

    <v-row  v-if="formComplete">
      <v-col>
        <span class="formCompleteMessage">
          We've received your meal choices. Thank you!
        </span>
      </v-col>
    </v-row>
    <!-- <v-btn
      
      @click="test"
      class="theme-btn"
    >
      test
      </v-btn> -->
  </v-container>
</template>

<script>

import axios from 'axios'

import https from 'https';


export default {

  data(){
    return {
      userData: null,
      menuData:null,
      password: '',
      guestCount:0,
      availableGuests:0,
      allDataIsValid: false,
      savingData:false,
      errorMessage: null,
      formComplete:false
    }
  },

  methods:{
    validateForm(){
      let isvalid = true;

      if(!this.userData){  
        return false
      }

      this.userData.forEach(user => {

        if(user.is_attending ){
          if(!user.main || !user.starter || !user.desert){
            
            isvalid = false;
          }
        }

      });
      
      //TODO make sure all items are from the same menu
      this.allDataIsValid = isvalid;
      console.log('isvalid',this.allDataIsValid)
    },
    test(){
        console.log(this.userData)
    },
    setToAdultMenu(user, adultMenuChosen){
      user.hasKidsOption = true;
      if(adultMenuChosen){
        user.menu_option = 'Main';
      }else{
        user.menu_option = 'Kids';
      }
      this.validateForm();
    },
    userSelectionUpdated(user, course, dish_id ){
      
      if(course == 'main'){
        user.main = dish_id;
      }
      
      if(course == 'starter'){
        user.starter = dish_id;
      }

      if(course == 'desert'){
        user.desert = dish_id;
      }

      this.validateForm();

      //TODO make sure all items are from the same menu

    },
    saveChoices(){
      if(this.allDataIsValid){
        this.savingData = true;
        axios.post(process.env.VUE_APP_API_BASE + '/users/choices', {
          password:this.password,
          invitees: this.userData
        })
        .then(response => {
          this.savingData = false;
          this.formComplete = true;
          console.log(response)
          this.errorMessage = null;
        })
        .catch(error => {
          this.savingData = false;
          this.errorMessage = 'There was an error saving your choices. Please try again'
          console.log(error)
        })
      }
    },
    getUserData(){
      if(this.password.length >0){
        axios.post(process.env.VUE_APP_API_BASE + '/users', {
          password: this.password
        }, {
          httpsAgent: new https.Agent({
            rejectUnauthorized: false
          })
        }
)
        .then(response => {
          
          if(response.data.choices_received){
            this.formComplete = true;
          }

          let   users = response.data.users;
          this.errorMessage = null;
          users.forEach(user => {
              if(user.is_attending ==1){
                user.is_attending = true
              }else{
                user.is_attending = false
              }
          });

          this.userData = users;

          this.menuData = this.parseMealData(response.data.menus);
        })
        .catch(error => {
          this.errorMessage = 'Please check your password and try again'
          console.log(error)
        })
      }
    },
    parseMealData(arr){
      const result = {
    main: {
      Starter: [],
      Main: [],
      Desert: [],
    },
    kids: {
      Starter: [],
      Main: [],
      Desert: [],
    },
  };

  arr.forEach((menuObj) => {
    const menuKey = Object.prototype.hasOwnProperty.call(menuObj, 'main') ? 'main' : 'kids';
    menuObj[menuKey].forEach((dish) => {
      const courseKey = dish.course;
      if (Object.prototype.hasOwnProperty.call(result[menuKey], courseKey)) {
        result[menuKey][courseKey].push(dish);
      } else {
        result[menuKey][courseKey] = [dish];
      }
    });
  });
  return result;
    }
  },
  watch:{
    userData(){
      this.validateForm();
    }
  }

}
</script>

<style>
.mainConatiner{
  margin-top: 4em;
}
body,
.v-label,
.v-field__input,
.v-select .v-field .v-field__input>input,
.v-select .v-field .v-field__input>input::placeholder
{
  font-family: "Muli", sans-serif !important;
  font-weight: lighter !important;
}

.v-field__input input::placeholder,
.v-field__input input{
  font-family: "Muli", sans-serif !important;
  font-weight: lighter !important;
}

.menuTypeLink{
  display: block;
  text-align: right;
  font-size: 0.8em;
}

.v-checkbox {
  margin-top: 1em;
  margin-bottom:-0.7em ;
}

.v-checkbox .v-input__details{
  display: none;
}

.directionsHolder{
  font-weight: lighter;
  text-align: right;
  display: block;
  font-style: italic;
  font-size: 0.8em;
}

.continueButton{
  margin-top: 1em;
}

.errors{
  color: rgb(234, 88, 88);
  font-weight: bold;
  display: block;
  text-align: right;
}

</style>