import { createStore } from 'vuex'


import {authenticationData} from './authentication/authenticationStoreData';

export default createStore({
  
  modules: {
       authenticationData,
      
     },
})