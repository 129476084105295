import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '@/Pages/Home/HomePage';

import LoginPage from '@/Pages/Auth/LoginPage';
import RegistrationPage from '@/Pages/Auth/RegistrationPage';



const routes = [
        {
            path: '/',
            name: 'HomePage',
            component:HomePage
        },
        {
            path: '/login',
            name: 'LoginPage',
            component:LoginPage
        },
        {
            path: '/register',
            name: 'RegistrationPage',
            component:RegistrationPage
        },
    
];


const router = createRouter({
    history: createWebHistory(),
    routes
  })  
export default router
  