import 'vuetify/styles' // Global CSS has to be imported

import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import store from './store/store.js'
import axios from '@/Services/axiosService'


axios.defaults.baseURL = process.env.VUE_APP_API_BASE;

// loadFonts()

const app = createApp(App).use(store).use(router).use(vuetify)


//Layout
app.component('side-menu', require('./Components/Layout/SideMenu').default);
//Auth
app.component('registration-form', require('./Components/Auth/RegistrationForm').default);
app.component('login-form', require('./Components/Auth/LoginForm').default);

const instance = axios.create({});
app.config.globalProperties.axios=instance

app.mount('#app')